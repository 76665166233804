<style lang="less" scoped>
.Preview-Work-Module {
}
</style>
<template>
  <div class="Preview-Work-Module">
    <Preview
      title="在线作业"
      backUrl="/teacher/work/toView/detail"
      :data="data"
    />
  </div>
</template>

<script>
import Preview from "../../exam/module/Preview.vue";
export default {
  name: "preview-work-module",
  components: { Preview },
  data() {
    return {
      data: [
        {
          total: 100,
          type: "多选题",
          child: [
            {
              total: 50,
              title: "马克思主义最重要的理论品质包括( )。",
              score: 0,
              list: [
                { key: "A", value: "理论实际联系" },
                { key: "B", value: "实事求是" },
                { key: "C", value: "一切从实际出发" },
                { key: "D", value: "深入实际、调查研究" },
                { key: "E", value: "在实践中检验和发展真理" },
              ],
              referAnswer: "A B C E",
              parse: "无",
            },
            {
              total: 50,
              title: "价值规律的基本内容和要求是( )。",
              score: 0,
              list: [
                {
                  key: "A",
                  value: "商品的价值量决定于生产该商品所耗费的个别劳动时间",
                },
                {
                  key: "B",
                  value: "商品交换必须按照各个生产者实际耗费的劳动量",
                },
                {
                  key: "C",
                  value: "商品的价值量决定于生产商品的社会必要劳动时间",
                },
                { key: "D", value: "商品必须按照价值量相等的原则进行交换" },
                { key: "E", value: "商品的价格与价值完全背离" },
              ],
              referAnswer: "C D",
              parse: "无",
            },
          ],
        },
        {
          total: 40,
          type: "问答题",
          child: [
            {
              total: 8,
              title:
                "I would prefer ________（呆在家里度周末，而不愿开车跑那么远）to your mother’s.",
              score: 0,
              list: [],
              referAnswer:
                "to spend the weekend at home rather than riving all the way",
              parse: "<p>test html</p>",
            },
            {
              total: 8,
              title: `
              <p>题干：Durectuib：This part is to test your ability to do oractical writing. </p>
              <p>题干：Durectuib：This part is to test your ability to do oractical writing. </p>
              <p>题干：Durectuib：This part is to test your ability to do oractical writing. </p>
              `,
              score: 0,
              list: [],
              referAnswer:
                "to spend the weekend at home rather than riving all the way",
              parse: "<p>test html</p>",
            },
          ],
        },
      ],
    };
  },
  methods: {
    back() {
      this.$router.push("exam");
    },
  },
};
</script>